/*:root {*/
/*  --loader-size: 7em;*/
/*  --check-height: --loader-size/2;*/
/*  --check-width: --check-height/2;*/
/*  --check-left: --loader-size/6 + --loader-size/12;*/
/*}*/

@font-face {
  font-family: 'SourceSansPro-Regular';
  src: local('MyFont'), url(fonts/SourceSansPro-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'SourceSansPro-Bold';
  src: local('MyFont'), url(fonts/SourceSansPro-Bold.ttf) format('woff');
}

@font-face {
  font-family: 'SourceSansPro-SemiBold';
  src: local('MyFont'), url(fonts/SourceSansPro-SemiBold.ttf) format('woff');
}

@keyframes fadein {
  from {
    opacity:0;
    transform: translate(0px,500px)
  }
  to {
    opacity:1;
    transform: translate(0px,0px)
  }
}

@keyframes fadeout {
  from {
    opacity:1;
    transform: translate(0px,0px)
  }
  to {
    opacity:0;
    transform: translate(0px,500px)
  }
}